$loader-main-color: #7228eb;
.loaderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--loader-bg);
  height: 100vh;
  gap: 15px;
  .loaderContainer {
    position: relative;
    opacity: 0;
    transition: opacity 1.5s ease;
    &::before {
      content: '';
      width: 80%;
      height: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url(../../assets/images/loader/loader.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 120px;
      z-index: 3;
    }
    .loader {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 300px;
      border-radius: 50%;
      background: conic-gradient(
        from 90deg at 50% 50%,
        rgba(227, 109, 163, 0) 0deg,
        rgba(194, 89, 184, 0.02) 0.04deg,
        $loader-main-color 360deg
      );
      animation: spin 3s linear infinite;
      .loaderCorner {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: $loader-main-color;
        right: 0;
        top: 50%;
        transform: translate(0%, -50%);
      }
      &::before {
        content: '';
        width: 80%;
        height: 80%;
        border-radius: 50%;
        z-index: 1;
        background: var(--loader-bg);
      }
    }
    &.loaderContainerVisible {
      opacity: 1;
    }
  }
  .loaderMessage {
    color: var(--white);
    white-space: break-spaces;
    text-align: center;
    opacity: 0;
    transition: opacity 1.5s ease;
    font-size: 14px;
    line-height: 24px;
  }
  .loaderMessageVisible {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
